import React, { useState } from "react";
import { Flex, Form, notification } from "antd";
import { AddShekelModalWrap } from "../sling.styles";
import { useNavigate } from "react-router-dom";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { LohButton } from "../../../../components/lib/Button";
import {
  getBankList,
  userFundWalletWithSquadco,
} from "../../../../network/wallet";
import { TbCurrencyNaira } from "react-icons/tb";
import { TextField } from "../../../../components/lib/Form";
import { validateBasicField } from "../../../../utils/function-helpers/form-validators";
import { useQuery } from "react-query";

interface IProps {
  virtualAccount: Record<string, any>;
  setShowAddShekelModal: any;
  showaAddDShekelModal: boolean;
}
const AddShekelModal: React.FC<IProps> = ({
  virtualAccount,
  setShowAddShekelModal,
  showaAddDShekelModal,
}) => {
  const [copied, setCopied] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: bankList } = useQuery(["bankList"], () => getBankList());

  const virtualAccountBank =
    bankList?.data?.find((bank: any) => bank?.code === virtualAccount?.bankCode)
      ?.name ?? "";

  const copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerText = virtualAccount?.accountNumber;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  const handleSquadcoFundWallet = async (values: Record<string, any>) => {
    setIsSubmitting(true);
    const originUrl = window.location.origin;
    const payload = {
      amount: Number(values.amount) * 100,
      redirectURL: `${
        originUrl.includes("localhost")
          ? "https://lohli-advertisers-web-frontend.vercel.app"
          : originUrl
      }/sling`,
      // redirectURL: `https://www.facebook.com`,
    };

    try {
      const fundResult = await userFundWalletWithSquadco(payload);
      setIsSubmitting(false);
      window.open(fundResult?.data?.url, "_blank");
      // window.location = fundResult?.data?.url;
    } catch (error: any) {
      setIsSubmitting(false);
      if (error?.response) {
        notification.open({
          message: `Upload Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const amount = Form.useWatch("amount", form) ?? 0;
  const commision = (amount / 100) * 30;
  const charges = amount * 0.012;
  const amountToCredit = amount - commision;

  return (
    <AddShekelModalWrap
      open={showaAddDShekelModal}
      onCancel={() => setShowAddShekelModal(false)}
      footer={null}
      centered
      copied={copied}
    >
      <Flex vertical align="start" className="adshekels__headersection">
        <h3 className="adshekels__header">Add Shekels</h3>
        <p className="adshekels__subheader">Fund your Wallet</p>
      </Flex>

      <Flex className="account__number_section" vertical align="center">
        <p className="copy__account__numbertext">
          Tap to copy your Lohli Automated Account Number
        </p>
        <Flex
          className="bank__section"
          vertical
          align="center"
          onClick={copyToClipboard}
        >
          <p className="bank__name">Bank: {virtualAccountBank}</p>
          <h1 className="account__number">
            {virtualAccount?.accountNumber}
            <span className="copied__text">copied</span>
          </h1>
          <h2 className="bank__name">{virtualAccount?.accountName}</h2>
        </Flex>

        <p className="copy__account__numbertext">
          Money transferred to this account will be converted and funded to your
          sling.
        </p>
      </Flex>

      {/* <Flex className="or__divider" justify="center">
        <p>or</p>
        <div></div>
      </Flex> */}
      {/* <Form form={form} onFinish={handleSquadcoFundWallet}>
        <label>Enter Amount</label>
        <TextField
          placeholder="Enter Amount to add to your Sling"
          name="amount"
          validator={validateBasicField}
          icon={<TbCurrencyNaira />}
          margin="0 0 .2rem  0"
          type="number"
        />
        <Flex justify="center">
          <span className="shekel__equavalent">1 shekel = ₦200</span>
        </Flex>

        <div className="shekel_breakdown">
          <Flex justify="stretch" gap={16} vertical>
            <Flex justify="stretch" gap={16} vertical>
              <Flex justify="space-between" align="center">
                <p>Amount to Fund:</p>
                <p>
                  ₦{amount.toLocaleString()} ({(amount / 200).toLocaleString()}{" "}
                  shekels)
                </p>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>Commission Rate (30%):</p>
                <p>
                  ₦{commision.toLocaleString()} (
                  {(commision / 200).toLocaleString()} shekels)
                </p>
              </Flex>
              <Flex justify="space-between" align="center">
                <p>Payment charges (1.2%):</p>
                <p>₦{charges}</p>
              </Flex>
            </Flex>
            <div className="shekel_breakdown_line" />
            <Flex justify="space-between" align="center">
              <p className="shekel_breakdown_summary">
                Amount to be Credited to Sling:
              </p>
              <p className="shekel_breakdown_summary">
                {" "}
                ₦{amountToCredit.toLocaleString()} (
                {(amountToCredit / 200).toLocaleString()} shekels)
              </p>
            </Flex>
          </Flex>
        </div>

        <Flex justify="center" gap=".7rem" className="buttons__wrap">
          <LohButton
            backgroundColor="transparent"
            borderColor="var(--lightGraySecondary)"
            color="var(--grayPrimary)"
            htmlType="button"
            onClick={() => setShowAddShekelModal(false)}
            width="50%"
            height="2.5rem"
            padding="0"
          >
            Cancel
          </LohButton>
          <LohButton
            width="50%"
            height="2.5rem"
            padding="0"
            loading={isSubmitting}
            htmlType="submit"
          >
            Submit
          </LohButton>
        </Flex>
      </Form> */}
    </AddShekelModalWrap>
  );
};

export default AddShekelModal;
