import React, { useEffect, useState } from "react";
import { Flex, notification } from "antd";
import { CustomForm } from "../../../../components/lib/Form";
import {
  validateBasicField,
  validateName,
  validatePhoneNumber,
} from "../../../../utils/function-helpers/form-validators";

import { LohButton } from "../../../../components/lib/Button";
import { useAdvertiserContext } from "../../../../context/advertiserContext/main";
import { BsEmojiSmileFill, BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import {
  getLoggedInAdvertiser,
  updateAdvertiserBasicInfo,
  updateAdvertiserProfile,
} from "../../../../network/auth";
import { BsPersonFill as PersonIcon } from "react-icons/bs";
import { GiSmartphone as Phone } from "react-icons/gi";
import { BsGenderAmbiguous as Gender } from "react-icons/bs";
import { RiAtLine } from "react-icons/ri";
import { ActionTypes } from "../../../../context";
import { IoLocationOutline } from "react-icons/io5";
import dayjs from "dayjs";

interface IProps {
  activeForm?: number;
  setActiveForm?: any;
}

const ProfilePagePersonalInformation: React.FC<IProps> = ({
  setActiveForm,
  activeForm,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [fillFormDetails, setFillFormDetails] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    username: "",
    phoneNumber: "",
    address: "",
  });

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  useEffect(() => {
    setFillFormDetails({
      firstName: user?.profile?.firstName,
      lastName: user?.profile?.lastName,
      gender: user?.profile?.gender,
      username: user?.profile?.username,
      phoneNumber: user?.profile?.phoneNumber,
      address: user?.profile?.address,
    });
    setDateOfBirth(user?.profile?.dateOfBirth ?? "");
  }, [user]);

  const handleBasicInformationSubmit = async (values: Record<string, any>) => {
    const payload: {
      username?: string;
      dateOfBirth?: string;
    } = {
      ...values,
      dateOfBirth: dateOfBirth,
    };
    if (user?.profile?.username === values.username) {
      delete payload["username"];
    }

    setIsSubmitting(true);
    try {
      const userUpdate = await updateAdvertiserProfile(payload);
      const userResult = await getLoggedInAdvertiser();

      dispatch({
        type: ActionTypes.CurrentUser,
        payload: { ...userResult?.data },
      });
      notification.open({
        message: `Success`,
        description: "Personal info updated successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);

      if (error?.response) {
        notification.open({
          message: `Update Failed`,
          description: error.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  };

  const personalInfoFormDetails = [
    {
      label: "First Name",
      name: "firstName",
      placeholder: "First Name",
      validator: validateName,
      icon: <PersonIcon />,
      width: "48.4%",
    },
    {
      label: "Last Name",
      name: "lastName",
      placeholder: "Last Name",
      validator: validateName,
      icon: <PersonIcon />,
      width: "48.4%",
    },

    {
      label: "Phone Number",
      name: "phoneNumber",
      placeholder: "Phone Number",
      validator: validatePhoneNumber,
      icon: <Phone />,
      width: "48.4%",
    },
    {
      name: "username",
      placeholder: "username",
      validator: validateBasicField,
      icon: <RiAtLine />,
      width: "48.4%",
      label: "User Name",
    },

    {
      label: "Gender",
      name: "gender",
      placeholder: "Gender",
      validator: validateBasicField,
      type: "select",
      dataSet: ["MALE", "FEMALE"],
      icon: <Gender />,
      width: "48.4%",
    },
    {
      name: "address",
      placeholder: "Address",
      validator: validateBasicField,
      icon: <IoLocationOutline />,
      width: "48.4%",
      label: "Address",
    },
    {
      label: "Date Of Birth",
      name: "dateOfBirth",
      placeholder: "Date Of Birth",
      validator: validateBasicField,
      type: "datepicker",
      handleChange: (e: any) => {
        setDateOfBirth(dayjs(e, "DD/MM/YYYY").toISOString());
      },
      defaultValue: dateOfBirth,
    },
  ];

  return (
    <Flex className="details__section">
      <Flex className="details__left" vertical>
        <Flex vertical>
          <h3 className="details__title">Personal info</h3>
          <p className="details__subtitle">Write a short information</p>
        </Flex>
        {activeForm !== 2 && (
          <LohButton
            padding="0"
            width="7rem"
            height="2.5rem"
            onClick={() => setActiveForm(2)}
          >
            Edit
          </LohButton>
        )}
      </Flex>
      <CustomForm
        formFinishButtonText="Save Changes"
        handleSubmit={handleBasicInformationSubmit}
        formDetails={personalInfoFormDetails}
        fillFormDetails={fillFormDetails}
        handleCancel={() => setActiveForm(0)}
        buttonMarginTop="1rem 0 0 0"
        isSubmitting={isSubmitting}
        isFormDisabled={activeForm !== 2}
        gap="1.5rem"
      />
    </Flex>
  );
};

export default ProfilePagePersonalInformation;
