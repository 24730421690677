import React, { useState, useEffect } from "react";
import { Flex, Form, Tooltip, notification } from "antd";
import DashboardLayout from "../../../components/layouts/DashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import { ProfilePageWrap } from "./profile.styles";
import { ProfileCameraIcon } from "../../../assets/images/dashboard/svgExports";
import { useAdvertiserContext } from "../../../context/advertiserContext/main";
import {
  BsEmojiSmileFill,
  BsEmojiSmileUpsideDownFill,
  BsPersonCircle,
} from "react-icons/bs";
import { getBankList, userCreateVirtualAccount } from "../../../network/wallet";
import { useQuery } from "react-query";
import ProfilePageBasicInformation from "./sections/basic-information";
import ProfilePageSocialLinks from "./sections/social-information";
import ProfilePagePersonalInformation from "./sections/personal-information";
import { validateImageFile } from "../../../utils/function-helpers/form-validators";
import {
  createUserAvatarUrl,
  getLoggedInAdvertiser,
  uploadAvatarToS3,
} from "../../../network/auth";
import { FiLoader } from "react-icons/fi";
import { ActionTypes } from "../../../context";
import { LohButton } from "../../../components/lib/Button";
import VerifyBvn from "./sections/verify-bvn";
import ProfilePageBankInformation from "./sections/bank-information";
import LohliLoader from "../../../components/lib/PageLoader/pageLoader";
import LohliAvatar from "../../../components/lib/Avatar/avatar";
import HelpIcon from "../../../assets/images/dashboard/help.svg";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [persistentError, setPersistentError] = useState<null | string>(null);
  const [activeForm, setActiveForm] = useState(0);
  const [isAvatarSubmitting, setIsAvatarSubmitting] = useState(false);
  const [showBvnModal, setShowBvnModal] = useState(false);
  const {
    data: virtualAccount,
    error: virtualAccountError,
    isLoading: virtualAccountLoading,
    isFetched: virtualAccountFetched,
    isFetching: virtualAccountFetching,
    refetch: virtualAccountRefetching,
    isFetchedAfterMount: virtualAccountFetchedAfterMount,
  } = useQuery(
    ["virtualAccountDetaILS"],
    async () => {
      try {
        const data = await userCreateVirtualAccount();
        return data;
      } catch (e: any) {
        console.log(e);
        if (e.response) throw e?.response.data;
      }
    },
    {
      onError: (err) => setPersistentError((err as any)?.message),
      onSuccess: () => setPersistentError(null),
    }
  );
  const { data: bankList } = useQuery(["bankList"], () => getBankList());

  console.log(virtualAccount, virtualAccountError);

  const virtualAccountBank =
    bankList?.data?.find(
      (bank: any) => bank?.code === virtualAccount?.data?.bankCode
    )?.name ?? "";

  const {
    dispatch,
    state: { user },
  } = useAdvertiserContext();

  const copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerText = virtualAccount?.data?.accountNumber;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  async function handleAvatarUpload(event: any) {
    if (!event?.target?.files[0]) {
      return;
    }
    const maxFileLimit = 1000000; // 64kb
    let file = event?.target?.files[0];

    if (file.size > maxFileLimit) {
      notification.open({
        message: `Upload Failed`,
        description: "Maximum file size is 1mb",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }
    if (!validateImageFile(file?.name)) {
      notification.open({
        message: `Upload Failed`,
        description: "Invalid file Type. Please re-try",
        icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
      });
      return;
    }
    setIsAvatarSubmitting(true);

    try {
      const avatar = await createUserAvatarUrl({
        fileName: file.name,
      });
      await uploadAvatarToS3(avatar.data.signedUrl, file);
      const userResult = await getLoggedInAdvertiser();

      dispatch({
        type: ActionTypes.CurrentUser,
        payload: { ...userResult?.data },
      });

      notification.open({
        message: `Success`,
        description: "Avatar uploaded successfully",
        icon: <BsEmojiSmileFill color="var(--bluePrimary)" />,
      });
      setIsAvatarSubmitting(false);
    } catch (e: any) {
      setIsAvatarSubmitting(false);

      if (e?.response) {
        notification.open({
          message: `FIle Upload Failed`,
          description: e.response.data.message,
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      } else {
        notification.open({
          message: `Error`,
          description:
            "There was an issue with your network. Pls try again later",
          icon: <BsEmojiSmileUpsideDownFill color="var(--errorPrimary)" />,
        });
      }
    }
  }

  // useEffect(() => {
  //   if (
  //     user?.bvn?.status !== "SUBMITTED" &&
  //     user?.bvn?.status !== "VERIFIED" &&
  //     user?.bankAccounts?.length > 0
  //   ) {
  //     setShowBvnModal(true);
  //   }
  // }, [user, showBvnModal]);

  return (
    <DashboardLayout>
      {user?.profile && (
        <ProfilePageWrap vertical copied={copied}>
          <Flex justify="space-between" align="center">
            <h1 className="search__header">Profile</h1>
          </Flex>

          <Flex
            className="profile__headersection"
            justify="space-between"
            align="center"
          >
            <Flex
              className="profile__headersection__left"
              align="flex-end"
              gap="1.5rem"
            >
              <div className="userimage__wrap">
                <LohliAvatar
                  imageUrl={user?.profile?.avatar?.publicURL || ""}
                />
                <span className="upload__wrap">
                  <input
                    hidden
                    type="file"
                    id="upload__thumbnail"
                    onChange={handleAvatarUpload}
                  />
                  <label
                    className="click__toupload"
                    htmlFor="upload__thumbnail"
                  >
                    {isAvatarSubmitting ? <FiLoader /> : <ProfileCameraIcon />}
                  </label>
                </span>
              </div>

              <Flex vertical className="header__lefttexts">
                <h3 className="agency__name">{user?.advertiserInfo?.name}</h3>
                <p className="username">
                  @{user?.profile?.username} ({user?.advertiserInfo?.type})
                </p>
              </Flex>
            </Flex>
            {
              // user?.bvn?.status !== "SUBMITTED" &&
              <>
                {user?.bvn?.status === "VERIFIED" ? (
                  <>
                    {(virtualAccount?.data || persistentError) && (
                      <Flex
                        vertical
                        align="center"
                        className="accountnumber__section"
                      >
                        <Flex gap={4} vertical align="center">
                          <Flex
                            className="accountnumber__section__top"
                            onClick={
                              persistentError ? undefined : copyToClipboard
                            }
                          >
                            <p className="bank__name">
                              {virtualAccountBank ?? ""}:
                            </p>
                            <p className="account__number">
                              {persistentError
                                ? "Error generating account"
                                : virtualAccount?.data?.accountNumber}
                            </p>
                            <span className="copied__text">copied</span>
                          </Flex>
                          <Flex className="accountnumber__section__top">
                            <p className="account__number">
                              {persistentError
                                ? persistentError
                                : virtualAccount?.data?.accountName}
                            </p>
                          </Flex>
                        </Flex>
                        {persistentError ? null : (
                          <p className="tap__instructions">
                            Tap to copy your Lohli Automated account
                          </p>
                        )}
                      </Flex>
                    )}
                  </>
                ) : (
                  <Flex align="center" gap={8}>
                    <Tooltip
                      overlayClassName="verify_bvn_disclaimer"
                      overlayInnerStyle={{
                        backgroundColor: "#fff",
                        padding: "12px",
                      }}
                      overlayStyle={{
                        maxWidth: "321px",
                      }}
                      placement="left"
                      title={
                        <Flex vertical align="start" gap={4}>
                          <p className="verify_bvn_disclaimer_title">
                            Ensure Your Details Are Correct
                          </p>
                          <p className="verify_bvn_disclaimer_subtitle">
                            Double-check that all your basic information is
                            accurate before verifying your BVN. This will help
                            avoid verification issues.
                          </p>
                        </Flex>
                      }
                    >
                      <img src={HelpIcon} width={17} height={17} />
                    </Tooltip>
                    <Flex
                      vertical
                      align="center"
                      className="accountnumber__section"
                    >
                      <LohButton
                        padding="0"
                        width="10rem"
                        height="2.5rem"
                        onClick={() => setShowBvnModal(true)}
                      >
                        Verify Bvn
                      </LohButton>
                    </Flex>
                  </Flex>
                )}
              </>
            }
          </Flex>

          {user?.bvn?.status !== "SUBMITTED" &&
            user?.bvn?.status !== "VERIFIED" && (
              <ProfilePageBankInformation
                activeForm={activeForm}
                setActiveForm={setActiveForm}
                setShowBvnModal={setShowBvnModal}
              />
            )}
          <ProfilePageBasicInformation
            activeForm={activeForm}
            setActiveForm={setActiveForm}
          />

          <ProfilePagePersonalInformation
            activeForm={activeForm}
            setActiveForm={setActiveForm}
          />
          <ProfilePageSocialLinks
            activeForm={activeForm}
            setActiveForm={setActiveForm}
          />

          <VerifyBvn
            showBvnModal={showBvnModal}
            setShowBvnModal={setShowBvnModal}
            virtualAccountRefetching={virtualAccountRefetching}
          />
        </ProfilePageWrap>
      )}
    </DashboardLayout>
  );
};

export default ProfilePage;
